import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import RightSidebar from './rightSidebar/RightSidebar'
import SideBar from './sidebar/SideBar'
import styled from 'styled-components'
import { useResponsive } from '@src/hooks/useResponsive'
import Footer from './footer/Footer'
import MobileSubHeader from './mobileSubHeader/MobileSubHeader'
import ScrollTopBtn from '@src/components/commons/button/ScrollTopBtn/ScrollTopBtn'
import Header from './header/Header'

interface ILayoutProps {
  children: ReactNode
}

export default function Layout(props: ILayoutProps) {
  const { asPath } = useRouter()
  const { isTablet } = useResponsive(1280)
  const { isMobile } = useResponsive(768)

  const noLayoutComponent =
    asPath.startsWith('/account') ||
    asPath.startsWith('/login') ||
    asPath.startsWith('/sign_up') ||
    asPath.startsWith('/billing_card/result') ||
    asPath.includes('/security') ||
    asPath.startsWith('/change-password') ||
    asPath.startsWith('/social') ||
    asPath.startsWith('/confirm-email') ||
    asPath.startsWith('/s/') ||
    asPath.startsWith('/app') ||
    asPath.startsWith('/user/linked')

  if (noLayoutComponent) {
    return <LayoutWrapper setNoOverflow={false}>{props.children}</LayoutWrapper>
  }

  const pathStartsWithProduct = asPath.startsWith('/wev/product/')
  const pathStartsWithTerms = asPath.startsWith('/terms/')

  return (
    <LayoutWrapper setNoOverflow={pathStartsWithProduct || pathStartsWithTerms}>
      <Header />
      <RightSidebar />
      <MainContainer isMobile={isTablet} isMain={asPath.startsWith('/#') || asPath === '/'}>
        <SideBar />
        <div
          className={
            `w-full flex` +
            (asPath.startsWith('/business') ? ` max-w-[1280px]` : '') +
            ((!isTablet && asPath.startsWith('/user')) ||
            (!isTablet && asPath.startsWith('/terms')) ||
            (!isTablet && asPath.startsWith('/management'))
              ? ` pr-[64px]`
              : '') +
            (isMobile ? ' pt-[54px]' : '')
          }
        >
          <div className={`w-full flex flex-col ` + (isMobile ? ' pb-[50px]' : '')}>
            <MobileSubHeader />
            {props.children}
            <ScrollTopBtn />
          </div>
        </div>
      </MainContainer>
      <Footer />
    </LayoutWrapper>
  )
}
const LayoutWrapper = styled.div<{ setNoOverflow: boolean }>`
  overflow: ${({ setNoOverflow }) => (setNoOverflow ? 'unset' : 'hidden')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 360px;
  min-height: 100vh;
  position: relative;
  @media ${({ theme }) => theme.device.pc} {
    min-width: 300px;
  }
`
const MainContainer = styled.div<{ isMobile: boolean; isMain: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-top: ${({ isMain }) => (isMain ? 'unset' : '80px')};
  unicode-bidi: isolate;

  /* padding-right: ${({ isMobile }) => (isMobile ? 'none' : '64px')}; */
  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 0px;
  }
`

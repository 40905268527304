import styled from 'styled-components'

export const ActiveHeader = styled.div<{
  $isHeaderHovered: boolean
  $height: number
}>`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 4rem;
  background-color: white;

  z-index: 9;
  .activeHeader_top {
    width: 100%;
    height: 80px;
    border-right: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    z-index: 4;
  }
  .activeHeader_content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    border-right: 1px solid #ededed;
    > div {
      display: flex;
      align-items: start;
      justify-content: space-between;
      width: 100%;
      max-width: 1280px;
      padding: 0px 30px;
      height: ${({ $isHeaderHovered, $height }) => ($isHeaderHovered ? `${$height}px` : '0px')};
      overflow: hidden;
      transition: ${({ $isHeaderHovered }) => ($isHeaderHovered ? 'height 0.4s ease-in-out' : 'none')};
      z-index: 5;
      .activeHeader_left {
        width: 146px;
        min-width: 146px;
        height: 100%;
      }
      .activeHeader_right {
        width: 130px;
        min-width: 130px;
        height: 100%;
      }
    }
  }
`

export const ActiveHeaderSiteMap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  max-width: 840px;
  height: fit-content;
  > ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 30px 5px;
    text-align: center;
    :hover {
      background-color: ${({ theme }) => theme.wev_color.sub_btn_bg};
    }
    > a > li {
      ${({ theme }) => theme.wev_font.Regular_16};
      :hover {
        ${({ theme }) => theme.wev_font.sBold_16};
      }
    }
  }
`

import { css } from 'styled-components'

export const wev_font = {
  Bold_72: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 72px;
    line-height: normal;

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 30px;
    }
  `,
  Bold_50: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 40px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 24px;
    }
  `,
  Bold_48: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 38px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 28px;
    }
  `,
  Bold_40: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 36px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 32px;
    }
  `,
  Bold_36: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 32px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Bold_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 24px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Bold_28: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 26px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Bold_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 22px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Bold_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 18px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,

  Bold_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 16px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 16px;
    }
  `,
  Bold_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,
  Bold_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: normal;
  `,
  sBold_36: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 32px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 26px;
    }
  `,
  sBold_32: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 28px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 24px;
    }
  `,

  sBold_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 26px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 22px;
    }
  `,
  sBold_28: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 24px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  sBold_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 22px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  sBold_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 18px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,
  sBold_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 16px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 16px;
    }
  `,
  sBold_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,
  sBold_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: normal;
  `,
  Medium_36: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 36px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 32px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 26px;
    }
  `,
  Medium_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 26px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 24px;
    }
  `,
  Medium_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 22px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Medium_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 18px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,
  Medium_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 16px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 16px;
    }
  `,
  Medium_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
  `,
  Medium_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
  `,
  Medium_12: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
  `,
  Regular_52: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 52px;
    line-height: normal;
    /* @media ${({ theme }) => theme.device.pc} {
      font-size: 36px;
    } */
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Regular_40: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 36px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 32px;
    }
  `,
  Regular_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 24px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Regular_28: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 26px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,

  Regular_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 22px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 20px;
    }
  `,
  Regular_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 18px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,
  Regular_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    @media ${({ theme }) => theme.device.pc} {
      font-size: 16px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,
  Regular_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 14px;
    }
  `,
  Regular_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
  `,
  Regular_12: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
  `,
  Regular_10: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: normal;
  `,
} as const

export type FontType = typeof wev_font

export const font = {
  Bold_72: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 72px;
    line-height: normal;
  `,
  Bold_50: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: normal;
  `,
  Bold_48: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: normal;
  `,
  Bold_40: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: normal;
  `,
  Bold_36: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: normal;
  `,
  Bold_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: normal;
  `,
  Bold_28: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: normal;
  `,
  Bold_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
  `,
  Bold_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: normal;
  `,

  Bold_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
  `,
  Bold_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
  `,
  Bold_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: normal;
  `,
  sBold_36: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: normal;
  `,
  sBold_32: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: normal;
  `,

  sBold_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: normal;
  `,
  sBold_28: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: normal;
  `,
  sBold_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
  `,
  sBold_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
  `,
  sBold_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: normal;
  `,
  sBold_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
  `,
  sBold_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: normal;
  `,
  Medium_36: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 36px;
    line-height: normal;
  `,
  Medium_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 30px;
    line-height: normal;
  `,
  Medium_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: normal;
  `,
  Medium_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: normal;
  `,
  Medium_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
  `,
  Medium_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: normal;
  `,
  Medium_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
  `,
  Medium_12: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
  `,
  Regular_52: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 52px;
    line-height: normal;
  `,
  Regular_40: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: normal;
  `,
  Regular_30: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: normal;
  `,
  Regular_28: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: normal;
  `,

  Regular_24: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
  `,
  Regular_20: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: normal;
  `,
  Regular_18: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
  `,
  Regular_16: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
  `,
  Regular_14: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
  `,
  Regular_12: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
  `,
  Regular_10: () => css`
    font-family: 'Pretendard', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: normal;
  `,
} as const

export type NewFontType = typeof font

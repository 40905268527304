import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@src/hooks/redux'
import { setIsHoverHeader } from '@src/store/modules/commons'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import * as S from './ActiveHeader.styled'

const ActiveHeader = () => {
  const isHoverHeader = useAppSelector((state) => state.commons.isHoverHeader)
  const [height, setHeight] = useState(0)
  const dispatch = useAppDispatch()
  const headerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('layout')
  useEffect(() => {
    if (headerRef.current) {
      setHeight(headerRef.current.scrollHeight)
    }
  }, [isHoverHeader])
  return (
    <>
      <S.ActiveHeader
        $isHeaderHovered={Boolean(isHoverHeader)}
        $height={height}
        onMouseEnter={() => dispatch(setIsHoverHeader(true))}
        onMouseLeave={() => dispatch(setIsHoverHeader(null))}
      >
        {Boolean(isHoverHeader) && <div className="activeHeader_top"></div>}
        <div className="activeHeader_content">
          <div>
            <div className="activeHeader_left"></div>
            <S.ActiveHeaderSiteMap ref={headerRef} onMouseLeave={() => dispatch(setIsHoverHeader('true'))}>
              <ul onMouseEnter={() => dispatch(setIsHoverHeader('wev'))}>
                <Link href={'/wev'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.소개')}</li>
                </Link>
                <Link href={'/wev/BI'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.BI')}</li>
                </Link>
              </ul>

              <ul onMouseEnter={() => dispatch(setIsHoverHeader('service'))}>
                <Link href={'/wev/service/app'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.APP')}</li>
                </Link>
                <Link href={'/wev/service/membership_card'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.멤버십카드')}</li>
                </Link>
                <Link href={'/wev/service/fee'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.충전 요금')}</li>
                </Link>
                <Link href={'/wev/service/station'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.스테이션')}</li>
                </Link>
              </ul>

              <ul onMouseEnter={() => dispatch(setIsHoverHeader('product'))}>
                <Link href={'/wev/product'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.전기차 충전기')}</li>
                </Link>
              </ul>

              <ul onMouseEnter={() => dispatch(setIsHoverHeader('consult'))}>
                <Link href={'/wev/install_consult'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.충전기 설치 신청')}</li>
                </Link>
              </ul>
              <ul onMouseEnter={() => dispatch(setIsHoverHeader('partnership'))}>
                <Link href={'/wev/partnership'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li style={{ whiteSpace: 'nowrap' }}>{t('site_map.운영서비스')}</li>
                </Link>
                <Link href={'/wev/partnership/consult'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.사업 제휴 신청')}</li>
                </Link>
              </ul>
              <ul onMouseEnter={() => dispatch(setIsHoverHeader('news'))}>
                <Link href={'/customer/notice'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.공지사항')}</li>
                </Link>
                <Link href={'/customer/event'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.이벤트')}</li>
                </Link>
                <Link href={'/customer/faq'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.FAQ')}</li>
                </Link>
                <Link href={'/customer/news'} onClick={() => dispatch(setIsHoverHeader(null))}>
                  <li>{t('site_map.뉴스')}</li>
                </Link>
              </ul>
            </S.ActiveHeaderSiteMap>
            <div className="activeHeader_right"></div>
          </div>
        </div>
      </S.ActiveHeader>
    </>
  )
}

export default ActiveHeader
